import { appSyncQuery } from './index';
import {
  ConjunctiveOperator,
  MoveDirection,
  Operator,
  QueryPair,
  RefundReasonCode,
  SortDirection,
  Transaction,
  Transactions,
} from '../GraphQL/internal_types';
import { GraphQLResult } from '@aws-amplify/api-graphql';

const listString = `query listTransactions($query: SqlQuery, $offset_id: String, $offset: String, $limit: Int, $direction: MoveDirection, $payment_method_query: [QueryPair], $payor_query: [QueryPair]) {
  transactions(query: $query, offset_id: $offset_id, offset: $offset, limit: $limit, direction: $direction) {
                    items {
                        merchant_uid
                        transaction_id
                        transaction_date
                        status
                        settlement_batch
                        payment_method(query_list: $payment_method_query) {
                          card_brand
                          last_four
                          payment_type
                          payor(query_list: $payor_query) {
                            full_name
                            email
                            phone
                          }
                        }
                        reference
                        account_code
                        transaction_type
                        dispute_status
                        net_amount
                        gross_amount
                        fees
                        currency
                        failure_reasons
                        refunded_amount
                      }
                      total_row_count
                    }
}`;

export type listTransactionsFilter = {
  transactionQuery: QueryPair[];
  paymentMethodQuery: QueryPair[];
  payorQuery: QueryPair[];
};

export const list = (
  order: SortDirection,
  offset: Transaction | null,
  limit: number,
  filter: listTransactionsFilter,
  direction: MoveDirection,
): Promise<GraphQLResult<{ transactions: Transactions }>> => {
  const sort = [{ key: 'transaction_date', direction: order }];
  const queryObject = {
    query_list: filter.transactionQuery,
    sort_list: sort,
  };
  const variables = {
    query: queryObject,
    offset_id: offset?.transaction_id,
    offset: offset?.transaction_date,
    limit: limit,
    direction: direction,
    payment_method_query: filter.paymentMethodQuery,
    payor_query: filter.payorQuery,
  };
  return appSyncQuery(listString, variables);
};

const getString = `query getTransactionAndRefunds($query: SqlQuery, $limit: Int = 100) {
                transactions(query: $query, limit: $limit) {
                  items {
                    merchant_uid
                    transaction_id
                    transaction_date
                    status
                    settlement_batch
                    payment_method {
                      card_brand
                      last_four
                      payment_type
                      payor {
                        full_name
                        email
                        phone
                      }
                    }
                    reference
                    account_code
                    transaction_type
                    dispute_status
                    net_amount
                    gross_amount
                    fees
                    currency
                    failure_reasons
                    refunded_amount
                    refund_reason {
                        reason_code
                        reason_details
                      }
                    ach_return_details {
                        return_code
                        return_details
                        transfer_type
                    }
                    parent_id
                  }
              }
            }`;

export const get = (transactionId: string) => {
  if (transactionId.includes(':')) {
    transactionId = transactionId.split(':')[0];
  }
  const query = {
    query_list: [
      {
        key: 'transaction_id',
        value: transactionId,
        operator: Operator.EQUAL,
        conjunctive_operator: ConjunctiveOperator.OR_NEXT,
      },
      {
        key: 'parent_id',
        value: transactionId,
        operator: Operator.EQUAL,
      },
    ],
  };
  const variables = {
    query: query,
  };
  return appSyncQuery(getString, variables);
};

const refundString = `mutation CreateRefund($amount: Int!, $refund_email: String, $reason_code: RefundReasonCode!, $transaction_id: String!, $reason_details: String) {
              createRefund(amount: $amount, refund_email: $refund_email, transaction_id: $transaction_id, refund_reason: {reason_code: $reason_code, reason_details: $reason_details})
            }`;

export const refund = (
  amount: string,
  reason: RefundReasonCode,
  description: string,
  refundEmail: string | null,
  transactionId: string,
) => {
  const amountInt = parseInt(amount.replace(/[^0-9]/g, ''));
  const refundVariables = {
    amount: amountInt,
    reason_code: reason,
    reason_details: description,
    refund_email: refundEmail,
    transaction_id: transactionId,
  };
  return appSyncQuery(refundString, refundVariables);
};

const errorString = `query GetLocalizedError($error_codes: [String]!, $language_code: String!) {
  errorText(error_codes: $error_codes, language_code: $language_code)
}`;

export const getLocalizedError = (
  error_codes: Array<string>,
  language_code: string = 'en-US',
): Promise<GraphQLResult<{ errorText: Array<string> }>> => {
  const variables = {
    error_codes,
    language_code,
  };
  return appSyncQuery(errorString, variables);
};
