export const Header = () => {
  return (
    <div className="login-logo-wrapper">
      <div className="login-logo" />
      <style jsx="true">{`
        .login-logo-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .login-logo {
          background-image: url(https://books-ui-assets.s3.amazonaws.com/pay_theory_primary_logo_purple.svg);
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center;
          height: 130px;
          width: 300px;
        }
      `}</style>
    </div>
  );
};

export const SignInHeader = () => {
  return (
    <h3 className="login-box-header">
      System Portal Sign In
      <style jsx="true">{`
        .login-box-header {
          margin-top: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
      `}</style>
    </h3>
  );
};
