import { appSyncQuery } from './index';
import * as gql from '../constants/GraphQL';
import {
  ListMerchant,
  Merchant,
  Merchants,
  MoveDirection,
  SortDirection,
} from '../GraphQL/internal_types';
import { GraphQLResult } from '@aws-amplify/api-graphql';

const listString = `query listMerchants($direction: MoveDirection, $limit: Int, $offset: String, $offset_id: String, $query: SqlQuery) {
  merchants(direction: $direction,
            limit: $limit,
            offset: $offset,
            offset_id: $offset_id,
            query: $query) {
    items {
      ach_active
      card_active
      cash_active
      is_system
      merchant_name
      merchant_uid
      parent_merchant_uid
      submitted_onboarding
      country_code
    }
    total_row_count
  }
}
`;

export const list = (
  order: SortDirection,
  offset: ListMerchant | undefined | null,
  limit: number,
  filter: string,
  direction: MoveDirection,
): Promise<GraphQLResult<{ merchants: Merchants }>> => {
  const sort = [{ key: 'merchant_name', direction: order }];
  const formattedFilter = filter
    ? { key: 'merchant_name', value: `%${filter}%`, operator: gql.LIKE }
    : null;
  const queryObject = {
    query_list: formattedFilter,
    sort_list: sort,
  };
  const variables = {
    query: queryObject,
    offset_id: offset?.merchant_uid,
    offset: offset?.merchant_name,
    limit: limit,
    direction: direction,
  };
  return appSyncQuery(listString, variables);
};

const queryString = `query Merchant($merchant_uid: String, $merchant_name: String) {
  merchant(merchant_uid: $merchant_uid, merchant_name: $merchant_name) {
    ach_active
    api_key
    card_active
    cash_active
    country_code
    fee_matrix {
      ach {
        merchant_fee {
          basis_points
          fixed
          max_fee
          __typename
        }
        service_fee {
          basis_points
          fixed
          max_fee
          min_fee
          __typename
        }
        __typename
      }
      ach_return_fee
      ach_return_disputed_fee
      business_credit {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        service_fee {
          basis_points
          fixed
          min_fee
          __typename
        }
        __typename
      }
      business_debit {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        service_fee {
          basis_points
          fixed
          min_fee
          __typename
        }
        __typename
      }
      amex {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        __typename
      }
      card {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        service_fee {
          basis_points
          fixed
          min_fee
          __typename
        }
        __typename
      }
      card_account_updater
      cash
      chargeback_fee
      credit_card {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        service_fee {
          basis_points
          fixed
          min_fee
          __typename
        }
        __typename
      }
      debit_card {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        service_fee {
          basis_points
          fixed
          min_fee
          __typename
        }
        __typename
      }
      discover {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        __typename
      }
      interchange_plus
      international_card_basis
      mastercard {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        __typename
      }
      merchant_uid
      prepaid_card {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        service_fee {
          basis_points
          fixed
          min_fee
          __typename
        }
        __typename
      }
      service_fee_enabled
      visa {
        merchant_fee {
          basis_points
          fixed
          __typename
        }
        __typename
      }
      __typename
    }
    fee_model {
      interchange_plus
      merchant_fee {
        ach_basis
        ach_fixed
        card_basis
        card_fixed
        __typename
      }
      service_fee {
        ach_basis
        ach_fixed
        card_basis
        card_fixed
        __typename
      }
      service_fee_min {
        ach_basis
        ach_fixed
        card_basis
        card_fixed
        __typename
      }
      __typename
    }
    is_system
    merchant_name
    merchant_uid
    parent_merchant_uid
    settings {
      contact_email
      contact_phone
      facebook
      instagram
      linkedin
      tiktok
      twitter
      website
      __typename
    }
    submitted_onboarding
    __typename
  }
}
`;

export const query = (
  uid: string,
): Promise<GraphQLResult<{ merchant: Merchant }>> => {
  const variables = {
    merchant_uid: uid,
  };
  return appSyncQuery(queryString, variables);
};

const createString = `mutation CreateMerchant($parent_merchant_uid: String, $merchant_name: String!, $user: MerchantUserInput) {
  createMerchant(merchant_name: $merchant_name, parent_merchant_uid: $parent_merchant_uid, user: $user) {
    ach_active
    card_active
    cash_active
    merchant_name
    merchant_uid
    parent_merchant_uid
    submitted_onboarding
  }
}
`;

export const create = (
  name: string,
  parent: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
) => {
  let user = null;
  // Only pass a user object if at least one of the fields is filled out
  if (firstName || lastName || email || phone) {
    user = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone || null,
    };
  }
  const variables = {
    merchant_name: name,
    parent_merchant_uid: parent,
    user,
  };
  return appSyncQuery(createString, variables);
};
