import * as merchants from './merchants';
import * as users from './users';
import * as transactions from './transactions';
import * as native from './native';
import * as secretkey from './secretkey';
import * as onboarding from './onboarding';
import { API, graphqlOperation } from 'aws-amplify';
// import {Observable} from "zen-observable-ts";

const configureAPI = (internal: boolean) => {
  // Lets us swap between our two endpoints for calling our internal and external APIs
  if (internal) {
    // @ts-ignore
    API._options.aws_appsync_graphqlEndpoint = API._options.endpoints[1];
    // @ts-ignore
    API._graphqlApi._options.aws_appsync_graphqlEndpoint =
      // @ts-ignore
      API._options.endpoints[1];
    // @ts-ignore
    API._graphqlApi._api._options.aws_appsync_graphqlEndpoint =
      // @ts-ignore
      API._options.endpoints[1];
  } else {
    // @ts-ignore
    API._options.aws_appsync_graphqlEndpoint = API._options.endpoints[0];
    // @ts-ignore
    API._graphqlApi._options.aws_appsync_graphqlEndpoint =
      // @ts-ignore
      API._options.endpoints[0];
    // @ts-ignore
    API._graphqlApi._api._options.aws_appsync_graphqlEndpoint =
      // @ts-ignore
      API._options.endpoints[0];
  }
};

const appSyncQuery = (
  query: string,
  variables: object | undefined,
  internal: boolean = false,
): Promise<any> => {
  configureAPI(internal);
  return API.graphql(graphqlOperation(query, variables || {})) as Promise<any>;
};

// const appSyncSubscription = <T extends object>(query: string, variables: object | undefined, onNext: (value: T) => void, onError: (error: any) => void, internal: boolean = false) => {
//     configureAPI(internal)
//     let call = API.graphql(graphqlOperation(query, variables || {})) as Observable<object>
//     return call.subscribe({
//         next: (response: { value: { data: T } }) => {
//             onNext(response.value.data)
//         },
//         error: onError
//     })
// };

export {
  merchants,
  users,
  transactions,
  appSyncQuery,
  secretkey,
  native,
  onboarding,
};
