/* global FreshworksWidget */
import React, { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Amplify } from 'aws-amplify';
import * as serviceWorker from './serviceWorker';
import './styles.css';
import { GlobalStyle } from '@paytheory/components.common.global_style';
import { BrowserRouter as Router } from 'react-router-dom';

const HideFreshworks = () => {
  useEffect(() => {
    // @ts-ignore
    FreshworksWidget('hide', 'launcher');
  }, []);
  return <div />;
};

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL, //'us-east-1:ffd0e720-da44-45ab-ac61-66422d32a552',

    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION, //'us-east-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USER_POOL, //'us-east-1_ut0KvX8ix',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT, //'7275mnpka5v5d0nmk1iav5de8m',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
  },
  endpoints: [
    `https://api.${process.env.REACT_APP_PARTNER}.${process.env.REACT_APP_STAGE}.com/graphql`,
    `https://internal.${process.env.REACT_APP_PARTNER}.${process.env.REACT_APP_STAGE}.com/graphql`,
  ],
  aws_appsync_graphqlEndpoint: ``,
  aws_appsync_region: process.env.REACT_APP_COGNITO_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
});

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(
  <Router>
    <StrictMode>
      <GlobalStyle />
      <HideFreshworks />
      {/*@ts-ignore*/}
      <App />
    </StrictMode>
  </Router>,
);
serviceWorker.unregister();
